import { ActionReducerMapBuilder, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Family, ProductCategory } from '../../enum/product.enum';
import {
  OrderFile,
  OrderForCreation,
  OrderForUpdate,
  OrderItem,
  OrderItemLight,
  OrdersReducer
} from '../../models/order';
import { DiagnosticCreation } from '../../models/patient';

const initialOrder: OrdersReducer = {
  order: {
    dentistName: undefined,
    dentistEmail: undefined,
    clinicName: undefined,
    clinicId: undefined,
    toManufacture: true,
    patient: { reference: undefined, diagnostic: undefined },
    expectedDate: undefined,
    instructions: undefined,
    labId: undefined,
    labName: undefined
  },
  files: [],
  items: [],
  currentItem: undefined,
  error: undefined,
  file3dToDisplay: undefined,
  fileTextureToDisplay: undefined,
  fileImageToDisplay: undefined,
  addProductStepIndex: 1,
  selectedFamilyValue: undefined,
  selectedCategoryValue: undefined,
  isLoadingFiles: false
};

export const ordersSlice = createSlice({
  name: 'orders',
  initialState: initialOrder,
  reducers: {
    setOrder: (state, action: PayloadAction<OrderForCreation | OrderForUpdate>) => {
      state.order = action.payload;
    },
    setDiagnostic: (state, action: PayloadAction<DiagnosticCreation>) => {
      state.order = {
        ...state.order,
        patient: {
          ...state.order.patient,
          diagnostic: action.payload
        }
      };
    },
    pushItem: (state, action: PayloadAction<OrderItemLight | OrderItem>) => {
      state.items = [...state.items, action.payload];
    },
    setItems: (state, action: PayloadAction<(OrderItemLight | OrderItem)[]>) => {
      state.items = action.payload;
    },
    setFiles: (state, action: PayloadAction<OrderFile[]>) => {
      state.files = action.payload;
    },
    setFile3dToDisplay: (state, action: PayloadAction<OrderFile | undefined>) => {
      state.file3dToDisplay = action.payload;
    },
    setFileImageToDisplay: (state, action: PayloadAction<OrderFile | undefined>) => {
      state.fileImageToDisplay = action.payload;
    },
    setFileTextureToDisplay: (state, action: PayloadAction<OrderFile | undefined>) => {
      state.fileTextureToDisplay = action.payload;
    },
    setCurrentItem: (state, action: PayloadAction<OrderItemLight | undefined>) => {
      state.currentItem = action.payload;
    },
    setError: (state, action: PayloadAction<string | undefined>) => {
      state.error = action.payload;
    },
    setAddProductStepIndex: (state, action: PayloadAction<number>) => {
      state.addProductStepIndex = action.payload;
    },
    setSelectedFamilyValue: (state, action: PayloadAction<Family>) => {
      state.selectedFamilyValue = action.payload;
    },
    setSelectedCategoryValue: (state, action: PayloadAction<ProductCategory>) => {
      state.selectedCategoryValue = action.payload;
    },
    setLoadingFiles: (state, action: PayloadAction<boolean>) => {
      state.isLoadingFiles = action.payload;
    },
    resetOrder: () => initialOrder
  },
  extraReducers: (builder: ActionReducerMapBuilder<OrdersReducer>) => {
    builder.addCase('RESET_ALL', () => {
      return { ...initialOrder };
    });
  }
});

export const ordersActions = ordersSlice.actions;
