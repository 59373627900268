import { useEffect, useState } from 'react';
import { Box, Button, Skeleton, Text } from '@anatoscope/circlestorybook';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { Order, OrderFile } from '../../../models/order';
import { ordersActions } from '../../../store/orders/orders.reducer';
import orderFormStyles from '../order-form.module.scss';
import {
  isLoadingFilesSelector,
  orderFile3dToDisplaySelector,
  orderFileImageToDisplaySelector,
  orderFilesSelector,
  orderFileTextureToDisplaySelector
} from '../../../store/orders/orders.selectors';
import styles from './patient-files-form.module.scss';
import useForm from '../../../utils/useForm';
import {
  addFile,
  areSameFiles,
  displayFileInViewer,
  removeFile
} from '../../file-manager/file.utils';
import { ErrorCode } from '../../../enum/feedback';
import { usePredictPatientFileLabelMutation } from '../../../services/orders-api.services';
import { FileLabelEnum } from '../../../enum/file-label';
import { ColorPropsEnum } from '../../../enum/color.enum';
import { deleteFileAction } from '../../file-manager/menu-entry';
import FileManagerContainer from '../../file-manager/FileManagerContainer';

type Props = {
  nextCallback: () => void;
  initialOrderInfo?: Order;
};
const PatientFilesForm = ({ nextCallback, initialOrderInfo }: Props) => {
  const isEditMode = !!initialOrderInfo;
  const { t } = useTranslation(['production']);
  const dispatch = useAppDispatch();
  const [errorMandatory, setErrorMandatory] = useState<boolean>(false);

  const orderFiles = useAppSelector(orderFilesSelector);
  const file3dToDisplay = useAppSelector(orderFile3dToDisplaySelector);
  const fileTextureToDisplay = useAppSelector(orderFileTextureToDisplaySelector);
  const fileImageToDisplay = useAppSelector(orderFileImageToDisplaySelector);
  const isLoadingFiles = useAppSelector(isLoadingFilesSelector);

  const [predictLabel] = usePredictPatientFileLabelMutation();

  useEffect(() => {
    // When editing an order, we need to wait for the files list to load to display a file in the viewer
    displayFileInViewer(orderFiles, orderFiles[orderFiles.length - 1], dispatch);
    if (orderFiles) {
      setErrorMandatory(false);
    }
  }, [orderFiles]);

  const deleteFile = async (fileToDelete: OrderFile): Promise<void> => {
    removeFile(dispatch, orderFiles, fileToDelete, file3dToDisplay);
  };

  const fileActions = (file: OrderFile) => [
    [
      deleteFileAction(file, () => {
        deleteFile(file);
      })
    ]
  ];

  const uploadFiles = async (newFilesToUpload: File[]): Promise<void> => {
    // Update new files with loading true
    addFile(dispatch, predictLabel, orderFiles, newFilesToUpload);
  };

  const submit = (): void => {
    if (!orderFiles?.length) {
      setErrorMandatory(true);
      return;
    }
    nextCallback();
  };

  const { handleSubmit } = useForm({}, submit);

  const handleLabelChangeValue = (file: OrderFile, newValue: FileLabelEnum): void => {
    const updatedOrderFiles = orderFiles.map((orderFile) =>
      areSameFiles(orderFile, file) ? { ...orderFile, fileLabel: newValue } : orderFile
    );
    dispatch(ordersActions.setFiles(updatedOrderFiles));
  };

  return (
    <Box color={ColorPropsEnum.WHITE} className={orderFormStyles['order-form__box']}>
      <form onSubmit={handleSubmit} className={styles['patient-files-form__form']}>
        <div className={styles['patient-files-form__form__files']}>
          {errorMandatory && (
            <Text
              label={t(ErrorCode.ORDERS_FILE_MANDATORY, { ns: 'error' })}
              color={ColorPropsEnum.DANGER}
            />
          )}
          {isEditMode && !initialOrderInfo && <Skeleton type="square" width="100%" height="100%" />}
          {((isEditMode && initialOrderInfo) || !isEditMode) && (
            <FileManagerContainer
              filesList={orderFiles}
              onLabelChangeCallback={(file: OrderFile, label: FileLabelEnum | string) =>
                handleLabelChangeValue(file, label as FileLabelEnum)
              }
              file3DToDisplay={file3dToDisplay}
              fileTextureToDisplay={fileTextureToDisplay}
              fileImageToDisplay={fileImageToDisplay}
              fileActions={fileActions}
              height={'375px'}
              isViewerLoading={isLoadingFiles}
              onUploadFileCallback={(newFiles: File[]) => uploadFiles(newFiles)}
              onClickFileCallback={(selectedFile: OrderFile) => {
                displayFileInViewer(orderFiles, selectedFile, dispatch);
              }}
            />
          )}
        </div>

        <div className="form__submit-button form__submit-button--right">
          <Button
            label={t('action.next', { ns: 'common' })}
            type="submit"
            category="secondary"
            iconRight="fa-chevron-right"
          />
        </div>
      </form>
    </Box>
  );
};
export default PatientFilesForm;
