import { useCallback, useEffect, useState } from 'react';
import {
  buildManufacturingComponentsFilters,
  colComponentOrderNumber,
  colComponentType,
  colFamily,
  colFiles,
  colManufactured,
  colManufacturingMenu,
  colMaterial,
  colProduct,
  colProductionType,
  colShade,
  rowClassname
} from './manufacturing';
import DatagridFeature from '../../../../../features/datagrid/DatagridFeature';
import { datagridSettingsActions } from '../../../../../store/datagrids-settings/datagrids-settings.reducers';
import { FilterReducer, LoadDataProps } from '../../../../../models/datagrid';
import { TypeSingleSortInfo } from '@inovua/reactdatagrid-community/types';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import { useGetCommonTypesQuery } from '../../../../../services/common-types-api.services';
import { manufacturingDatagridStateSelector } from '../../../../../store/datagrids-settings/datagrids-settings.selectors';
import { useTranslation } from 'react-i18next';
import styles from '../../production-page.module.scss';
import { Button, SideBarModal } from '@anatoscope/circlestorybook';
import { ActionBtn, buildSort, colDate, colStatus } from '../../../../../features/datagrid/columns';
import { feedbackActions } from '../../../../../store/feedback/feedback.reducer';
import {
  usePatchManufacturingComponentProductionTypeMutation,
  usePatchManufacturingStepComponentMutation,
  useSetComponentManufacturedMutation
} from '../../../../../services/products-api.services';
import { WorkflowManufacturingStepEnum, WorkflowStepEnum } from '../../../../../enum/workflow-step';
import { useLazyGetProductionFilesDownloadUrlQuery } from '../../../../../services/orders-api.services';
import {
  useLazyGetManufacturingComponentsQuery,
  usePatchComponentMutation
} from '../../../../../services/components-api.services';
import { ManufacturingComponentView } from '../../../../../models/component';
import { ManufacturingProductionTypeEnum } from '../../../../../enum/component';
import { getNextStepEnum, getPreviousStepEnum, isStepBackExists } from './manufacturing-steps';
import { ToastType } from '../../../../../enum/feedback';
import QuickFilter from '../../../../../features/quick-filter/QuickFilter';
import AssignForm from '../../../../../features/assign-form/AssignForm';
import { colAssignee } from '../design/design';
import Comments from '../../../../../features/comments/Comments';
import DatagridReload from '../../../../../features/datagrid/DatagridReload';
import { TypeFilterValue } from '@inovua/reactdatagrid-community/types/TypeFilterValue';
import { TypeSortInfo } from '@inovua/reactdatagrid-community/types/TypeSortInfo';
import {
  useDownloadFileFromStorageMutation,
  useLazyGetProductionZipFileQuery
} from '../../../../../services/files-api.services.ts';
import { downloadFile } from '../../../../../features/file-manager/file.utils.ts';
import { isManufacturingOrderOneDesignCompliant } from '../../../../../utils/order.utils.ts';
import { useLazyGetOneManufacturingOrderQuery } from '../../../../../services/manufacturing-orders-api.services.tsx';
import { ManufacturingOrder } from '../../../../../models/manufacturing-order.tsx';

enum QuickFilterEnum {
  MACHINING = 'MACHINING',
  PRINT = 'PRINT',
  NONE = 'NONE'
}

const ManufacturingPage = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(['production']);
  const datagridSettings = useAppSelector(manufacturingDatagridStateSelector);
  const [getManufacturingComponents] = useLazyGetManufacturingComponentsQuery();
  const [manufacturingStepMessage, setManufacturingStepMessage] = useState<string | undefined>(
    undefined
  );
  const [selectedQuickFilter, setSelectedQuickFilter] = useState(QuickFilterEnum.NONE);
  const [selectedComponent, setSelectedComponent] = useState<ManufacturingComponentView | null>(
    null
  );
  const [sideBarOpened, setSideBarOpened] = useState(false);
  const [displayAssignForm, setDisplayAssignForm] = useState<boolean>(false);
  const [displayCommentsForm, setDisplayCommentsForm] = useState<boolean>(false);
  const [isReloadNeeded, setIsReloadNeeded] = useState<boolean>(false);
  const [reloadDate, setReloadDate] = useState<Date>();

  const [getOneManufacturingOrder] = useLazyGetOneManufacturingOrderQuery();
  const [
    setComponentManufactured,
    { isLoading: isLoadingManufacturingComponent, isSuccess: isSetComponentManufacturedSuccess }
  ] = useSetComponentManufacturedMutation();
  const [
    patchManufacturingStepComponent,
    {
      isLoading: isLoadingManufacturingStepComponent,
      isSuccess: isPatchManufacturedStepComponentSuccess
    }
  ] = usePatchManufacturingStepComponentMutation();
  const [
    patchManufacturingComponentProductionType,
    {
      isLoading: isLoadingManufacturingComponentProductionType,
      isSuccess: isPatchManufacturingComponentProductionTypeSuccess
    }
  ] = usePatchManufacturingComponentProductionTypeMutation();
  const [patchComponent, { isSuccess: isSuccessAssignComponent, data: patchedComponent }] =
    usePatchComponentMutation();

  const [getProductionFilesDownloadUrl, { isLoading: isDownloadingFiles }] =
    useLazyGetProductionFilesDownloadUrlQuery();
  const [getProductionZipFile, { isLoading: isDownloadingProductionFiles }] =
    useLazyGetProductionZipFileQuery();
  const [downloadFromStorage] = useDownloadFileFromStorageMutation();
  const { data: commonTypes, isLoading: areCommonTypesLoading } = useGetCommonTypesQuery();

  useEffect(() => {
    if (
      (isPatchManufacturedStepComponentSuccess || isSetComponentManufacturedSuccess) &&
      manufacturingStepMessage
    ) {
      dispatch(
        feedbackActions.setToast({
          message: t(manufacturingStepMessage),
          type: ToastType.SUCCESS
        })
      );
    }
  }, [isSetComponentManufacturedSuccess, isPatchManufacturedStepComponentSuccess]);

  useEffect(() => {
    if (isPatchManufacturingComponentProductionTypeSuccess) {
      dispatch(
        feedbackActions.setToast({
          message: t('toast.component.productionType.success'),
          type: ToastType.SUCCESS
        })
      );
    }
  }, [isPatchManufacturingComponentProductionTypeSuccess]);

  useEffect(() => {
    if (isSuccessAssignComponent) {
      const message = patchedComponent?.assigneeEmail
        ? t('assignOrderModal.success')
        : t('assignComponentManufacturingModal.successUnassign', { ns: 'production' });
      dispatch(
        feedbackActions.setToast({
          message: message,
          type: ToastType.SUCCESS
        })
      );
      setSelectedComponent(null);

      if (!patchedComponent?.assigneeEmail) {
        // reload datagrid for unassignment
        setIsReloadNeeded(true);
      }
    }
  }, [isSuccessAssignComponent]);

  const loadData = async ({ skip, limit, sortInfo, filterValue }: LoadDataProps) => {
    const page = skip >= limit ? skip / limit + 1 : 1;
    const filters = buildManufacturingComponentsFilters(
      filterValue,
      WorkflowStepEnum.MANUFACTURING
    );
    const sort = buildSort(sortInfo);
    return getManufacturingComponents({ page, limit, filters, sort })
      .unwrap()
      .then((components) => {
        setReloadDate(new Date());
        return { data: components.data, count: components.meta.totalItems };
      })
      .catch(() => {
        return { data: [], count: 0 };
      });
  };

  const dataSource = useCallback(loadData, [
    commonTypes,
    isLoadingManufacturingComponent,
    isLoadingManufacturingStepComponent,
    isLoadingManufacturingComponentProductionType,
    isReloadNeeded
  ]);

  useEffect(() => {
    if (isReloadNeeded) {
      setIsReloadNeeded(false);
    }
  }, [isReloadNeeded]);

  const onFilterValueChange = (newFilterValue: TypeFilterValue) => {
    dispatch(
      datagridSettingsActions.setDatagridFilters({
        datagrid: 'manufacturing',
        filters: newFilterValue as FilterReducer[]
      })
    );
  };

  const onSortInfoChange = (newSortInfo: TypeSortInfo) => {
    dispatch(
      datagridSettingsActions.setDatagridSort({
        datagrid: 'manufacturing',
        sort: newSortInfo as TypeSingleSortInfo
      })
    );
  };

  const handleManufacturingStepActionCallback = async (component: ManufacturingComponentView) => {
    if (component?.productId) {
      const nextStep = getNextStepEnum(
        component.manufacturingStep
      ) as WorkflowManufacturingStepEnum;
      patchManufacturingStepComponent({
        productId: component.productId,
        componentId: component.id,
        manufacturingStep: nextStep
      });

      switch (component.manufacturingStep) {
        case WorkflowManufacturingStepEnum.TO_DESIGN:
        case WorkflowManufacturingStepEnum.DESIGNED:
          setManufacturingStepMessage(`toast.component.${nextStep.toLowerCase()}`);
          break;
        case WorkflowManufacturingStepEnum.TO_MANUFACTURE:
          setComponentManufactured({
            productId: component.productId,
            componentId: component.id
          });
          setManufacturingStepMessage(`toast.component.${nextStep.toLowerCase()}`);
          break;
        default:
          break;
      }
    } else {
      // If product id is not set or the manufacturing step is not one the managed cases.
      // For sure, it's a pessimistic case.
      dispatch(
        feedbackActions.setToast({
          message: t('error.generic', { ns: 'common' }),
          type: ToastType.DANGER
        })
      );
    }
  };

  const handlePreviousStepActionCallback = async (component: ManufacturingComponentView) => {
    if (component?.productId && isStepBackExists(component.manufacturingStep)) {
      const previousStep = getPreviousStepEnum(
        component.manufacturingStep
      ) as WorkflowManufacturingStepEnum;
      patchManufacturingStepComponent({
        productId: component.productId,
        componentId: component.id,
        manufacturingStep: previousStep
      });
      setManufacturingStepMessage(`toast.component.back.${previousStep.toLowerCase()}`);
    } else {
      // If product id is not set or the manufacturing step is not one the managed cases.
      // For sure, it's a pessimistic case.
      dispatch(
        feedbackActions.setToast({
          message: t('error.generic', { ns: 'common' }),
          type: ToastType.DANGER
        })
      );
    }
  };

  const downloadProductionFiles = (downloadUrl: string) => {
    if (downloadUrl) {
      downloadFromStorage({ url: downloadUrl })
        .unwrap()
        .then((blobFile) => {
          const fileName = downloadUrl?.substring(
            downloadUrl?.lastIndexOf('/') + 1,
            downloadUrl?.lastIndexOf('.')
          );
          downloadFile({
            extension: 'zip',
            fileName: fileName,
            mimeType: 'application/zip',
            data: new File([blobFile], fileName, {
              type: 'application/zip'
            })
          });
        });
      document.body.style.cursor = 'default';
    }
  };

  const handleClickDownload = (component: ManufacturingComponentView): void => {
    document.body.style.cursor = 'wait';
    dispatch(
      feedbackActions.setToast({
        message: t('fileDownload.pending'),
        type: ToastType.INFO
      })
    );
    getOneManufacturingOrder(component.orderNumber!)
      .unwrap()
      .then((order: ManufacturingOrder) => {
        if (isManufacturingOrderOneDesignCompliant(order)) {
          getProductionZipFile({
            orderNumber: component.orderNumber!,
            productFamily: component.family!,
            labName: 'CIRCLEONE'
          })
            .unwrap()
            .then((productionZipFileUrl) => downloadProductionFiles(productionZipFileUrl))
            .catch(() => (document.body.style.cursor = 'default'));
        } else {
          getProductionFilesDownloadUrl(component.orderNumber!)
            .unwrap()
            .then((productionZipFileUrl) =>
              downloadProductionFiles(productionZipFileUrl.downloadUrl)
            )
            .catch(() => (document.body.style.cursor = 'default'));
        }
      });
  };

  const removeFilters = () => {
    setSelectedQuickFilter(QuickFilterEnum.NONE);
    dispatch(datagridSettingsActions.resetDatagridFilters({ datagrid: 'manufacturing' }));
  };

  const toggleMachiningFilter = () => {
    if (selectedQuickFilter !== QuickFilterEnum.MACHINING) {
      setSelectedQuickFilter(QuickFilterEnum.MACHINING);
      dispatch(
        datagridSettingsActions.setManufacturingDatagridQuickFilters(
          ManufacturingProductionTypeEnum.MACHINING
        )
      );
    } else {
      removeFilters();
    }
  };

  const togglePrintFilter = () => {
    if (selectedQuickFilter !== QuickFilterEnum.PRINT) {
      setSelectedQuickFilter(QuickFilterEnum.PRINT);
      dispatch(
        datagridSettingsActions.setManufacturingDatagridQuickFilters(
          ManufacturingProductionTypeEnum.PRINT
        )
      );
    } else {
      removeFilters();
    }
  };

  const handleClickSetMachining = (component: ManufacturingComponentView) => {
    patchManufacturingComponentProductionType({
      productId: component.productId,
      componentId: component.id,
      tooth: ManufacturingProductionTypeEnum.MACHINING
    });
  };

  const handleClickSetPrint = (component: ManufacturingComponentView) => {
    patchManufacturingComponentProductionType({
      productId: component.productId,
      componentId: component.id,
      tooth: ManufacturingProductionTypeEnum.PRINT
    });
  };

  const handleClickAssign = (component: ManufacturingComponentView) => {
    setSelectedComponent(component);
    setSideBarOpened(true);
    setDisplayAssignForm(true);
  };

  const handleClickUnassign = async (component: ManufacturingComponentView) => {
    setSelectedComponent(component);
    patchComponent({
      productId: component.productId,
      id: component.id,
      assigneeEmail: null,
      assigneeName: null
    });
  };

  const handleClickComment = (component: ManufacturingComponentView) => {
    setSelectedComponent(component);
    setSideBarOpened(true);
    setDisplayCommentsForm(true);
  };

  const commentActionBtn: ActionBtn<ManufacturingComponentView> = {
    label: t('comments.comment', { ns: 'comment' }),
    onClick: (data: ManufacturingComponentView) => {
      handleClickComment(data);
    }
  };

  const onSubmitAssignManufacturingOrder = () => {
    setIsReloadNeeded(true);
    handleClickCloseSidebar();
  };

  const handleClickCloseSidebar = () => {
    setSideBarOpened(false);
    setDisplayAssignForm(false);
    setDisplayCommentsForm(false);
  };

  return (
    <>
      <div className={styles['production-page__filters__manufacturing']}>
        <div className={styles['production-page__filters__manufacturing__quickfilter']}>
          <QuickFilter
            label={t('quickfilters.toMachine')}
            isSelected={selectedQuickFilter === QuickFilterEnum.MACHINING}
            onClick={toggleMachiningFilter}
          />
          <QuickFilter
            label={t('quickfilters.toPrint')}
            isSelected={selectedQuickFilter === QuickFilterEnum.PRINT}
            onClick={togglePrintFilter}
          />
        </div>
        <div className={styles['production-page__filters__manufacturing__reset']}>
          <Button
            data-cy="reset-filters"
            category="tertiary"
            label={t('datagrid.removeAllFilters', { ns: 'common' })}
            onClick={removeFilters}
          />
          {reloadDate && (
            <DatagridReload reloadDate={reloadDate} setIsReloadNeeded={setIsReloadNeeded} />
          )}
        </div>
      </div>
      {!areCommonTypesLoading && commonTypes && (
        <DatagridFeature
          key="manufacturing"
          style={{ minHeight: 'calc(100dvh - 14.5rem)' }}
          dataSource={dataSource}
          filterValue={datagridSettings.filters}
          onFilterValueChange={onFilterValueChange}
          sortInfo={datagridSettings.sort}
          onSortInfoChange={onSortInfoChange}
          rowHeight={46}
          showZebraRows={false}
          columns={[
            colStatus,
            colComponentOrderNumber(dispatch, commentActionBtn),
            colFamily(commonTypes),
            colProduct,
            colComponentType(commonTypes),
            colMaterial(commonTypes),
            colProductionType,
            colShade(commonTypes),
            colAssignee(handleClickAssign),
            colManufactured(
              handleManufacturingStepActionCallback,
              handlePreviousStepActionCallback
            ),
            colFiles(handleClickDownload, isDownloadingFiles || isDownloadingProductionFiles),
            colDate('limitShippingDate'),
            colManufacturingMenu(
              handleClickSetMachining,
              handleClickSetPrint,
              handleClickAssign,
              handleClickUnassign,
              handleClickComment
            )
          ]}
          rowClassName={rowClassname}
        />
      )}

      {displayAssignForm && selectedComponent && (
        <SideBarModal
          title={t('assignOrderModal.title.component', {
            componentType: t(`componentType.${selectedComponent.componentType}`, {
              ns: 'component'
            })
          })}
          isOpened={sideBarOpened}
          closeOnOutsideClick={true}
          onClose={handleClickCloseSidebar}>
          <AssignForm
            onSubmitAssignManufacturingOrderCallback={onSubmitAssignManufacturingOrder}
            productId={selectedComponent.productId}
            componentId={selectedComponent.id}
            mode={'manufacturing'}
          />
        </SideBarModal>
      )}
      {displayCommentsForm && selectedComponent && (
        <SideBarModal
          title={t('comments.title', {
            orderNumber: selectedComponent.orderNumber,
            ns: 'comment'
          })}
          isOpened={sideBarOpened}
          closeOnOutsideClick={true}
          onClose={handleClickCloseSidebar}>
          {selectedComponent.orderNumber && (
            <Comments
              orderNumber={selectedComponent.orderNumber}
              inSidebar={true}
              onFirstCommentAddedCallback={() => setIsReloadNeeded(true)}
            />
          )}
        </SideBarModal>
      )}
    </>
  );
};

export default ManufacturingPage;
