import { api } from './api';
import { OrderFile } from '../models/order';
import { Family } from '../enum/product.enum.ts';

export const filesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getOneDownloadableFile: builder.query<OrderFile, { orderNumber: string; fileId: number }>({
      query: (arg: { orderNumber: string; fileId: number }) => {
        const { orderNumber, fileId } = arg;
        return {
          url: `/orders/${orderNumber}/patient-files/${fileId}`,
          method: 'GET'
        };
      }
    }),
    getProductionZipFile: builder.query<
      string,
      { orderNumber: string; productFamily: Family; labName: string }
    >({
      query: (arg: { orderNumber: string; productFamily: Family; labName: string }) => ({
        url: `/files?case=${arg.orderNumber}&lab=${arg.labName}&product_type=${arg.productFamily}`,
        responseHandler: 'text'
      })
    }),
    downloadFileFromStorage: builder.mutation<BlobPart, { url: string }>({
      query: (arg: { url: string }) => {
        const { url } = arg;
        const encodedUrl = `${url.substring(0, url.lastIndexOf('/') + 1)}${encodeURIComponent(
          url.substring(url.lastIndexOf('/') + 1)
        )}`;
        return {
          url: encodedUrl,
          method: 'GET',
          responseHandler: async (response: Response) => await response.blob()
        };
      }
    })
  })
});

export const {
  useLazyGetOneDownloadableFileQuery,
  useLazyGetProductionZipFileQuery,
  useDownloadFileFromStorageMutation
} = filesApi;
