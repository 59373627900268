export enum ErrorUIType {
  ATTENTION_BOX = 'ATTENTION_BOX',
  TOAST = 'TOAST',
  FIELD = 'FIELD'
}

export enum ToastType {
  SUCCESS = 'success',
  WARNING = 'warning',
  DANGER = 'danger',
  INFO = 'info'
}

export enum ErrorCode {
  auth_wrong_password = 'auth/wrong-password',
  auth_user_not_found = 'auth/user-not-found',
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  auth_invalid_email = 'auth/user-not-found',
  auth_user_token_expired = 'auth/user-token-expired',
  auth_too_many_requests = 'auth/too-many-requests',
  auth_missing_password = 'auth/missing-password',
  USR_ESTABLISHMENT_001 = 'USR-ESTABLISHMENT-001',
  USR_ESTABLISHMENT_002 = 'USR-ESTABLISHMENT-002',
  USR_ESTABLISHMENT_003 = 'USR-ESTABLISHMENT-003',
  USR_USERS_001 = 'USR-USERS-001',
  USR_USERS_002 = 'USR-USERS-002',
  USR_FIREBASE_001 = 'USR-FIREBASE-001',
  USR_FIREBASE_002 = 'USR-FIREBASE-002',
  USR_FIREBASE_003 = 'USR-FIREBASE-003',
  USR_FIREBASE_004 = 'USR-FIREBASE-004',
  USR_FIREBASE_005 = 'USR-FIREBASE-005',
  USR_FIREBASE_006 = 'USR-FIREBASE-006',
  ORDERS_ORDERS_002 = 'ORDERS-ORDERS-002',
  ORDERS_ORDERS_007 = 'ORDERS-ORDERS-007',
  USR_PAYLOAD_VALIDATIO_001 = 'USR-PAYLOAD-VALIDATION-001',
  USR_PAYLOAD_VALIDATION_002 = 'USR-PAYLOAD-VALIDATION-002',
  USR_ACCESS_001 = 'USR-ACCESS-001',
  MANU_ORDER_003 = 'MANU-ORDER-003',
  MANU_COMP_002 = 'MANU-COMP-002',
  ORDERS_FILE_MANDATORY = 'ORDERS-FILE-MANDATORY',
  MANU_PAYLOAD_VALIDATION_002 = 'MANU-PAYLOAD-VALIDATION-002',
  ORDERS_FILE_ALREADY_EXISTS = 'ORDERS-FILE-ALREADY-EXISTS',
  ORDERS_FILE_EMPTY = 'ORDERS-FILE-EMPTY',
  ORDERS_FILE_NOT_ALLOWED = 'ORDERS-FILE-NOT-ALLOWED',
  ORDERS_STORAGE_001 = 'ORDERS-STORAGE-001',
  ORDERS_STORAGE_002 = 'ORDERS-STORAGE-002',
  ORDERS_STORAGE_003 = 'ORDERS-STORAGE-003',
  ORDERS_STORAGE_004 = 'ORDERS-STORAGE-004',
  ORDERS_ORDERS_009 = 'ORDERS-ORDERS-009',
  ORDERS_ORDERS_010 = 'ORDERS-ORDERS-010',
  ORDERS_ORDERS_011 = 'ORDERS-ORDERS-011',
  ORDERS_PATIENT_001 = 'ORDERS-PATIENT-001',
  ORDERS_PATIENT_002 = 'ORDERS-PATIENT-002',
  ORDERS_PATIENT_003 = 'ORDERS-PATIENT-003',
  ORDERS_PATIENT_004 = 'ORDERS-PATIENT-004',
  ORDERS_PATIENT_005 = 'ORDERS-PATIENT-005',
  ORDERS_AUTH_001 = 'ORDERS-AUTH-001',
  MANU_AUTH_001 = 'MANU-AUTH-001'
}
